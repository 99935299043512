// import icons
import { FaYoutube, FaInstagram, FaGithub } from 'react-icons/fa';
import { BsChatDotsFill } from 'react-icons/bs';

// import images
import AboutImg from '../src/assets/img/about.png';
import Feature1Img from '../src/assets/img/features/feature1.png';
import Feature2Img from '../src/assets/img/features/feature2.png';
import Feature3Img from '../src/assets/img/features/feature3.png';
import Feature4Img from '../src/assets/img/features/feature4.png';
import Avatar1Img from '../src/assets/img/testimonials/avatar1.png';
import Avatar2Img from '../src/assets/img/testimonials/avatar2.png';
import Avatar3Img from '../src/assets/img/testimonials/avatar3.png';
import LogoV2 from '../src/assets/img/logo-v2.png';
import HeroImage from '../src/assets/img/hero-img.png';
import Feature1BgImg from '../src/assets/img/features/feature1_bg.png';
import Feature2BgImg from '../src/assets/img/features/feature2_bg.png';
import Feature3BgImg from '../src/assets/img/features/feature3_bg.png';
import Feature4BgImg from '../src/assets/img/features/feature4_bg.png';

export const navigationData = [
  {
    name: 'About',
    href: '#',
  },
  {
    name: 'Tailor-Made Resumes',
    href: '#',
  },
  {
    name: 'Templates',
    href: '#',
  },
  {
    name: 'Contact',
    href: '/contact',
  },
];

export const heroData = {
  title: `Hi, I'm Alec. A yacht captain & crew recruitment specialist`,
  subtitle:
    'With well over a decade of experience in the yachting industry and having thousands of resumes come across my desk over the years, I now know exactly what recruitment agents and Captains are looking for on a CV. ',
  btnText: 'Learn more',
  image: HeroImage,
};

export const aboutData = {
  image: AboutImg,
  title: 'Our goal is to get you the job of your dreams!',
  subtitle:
    'Not everyone realizes it, but for every job listing, there are dozens or even hundreds of applicants making it extremely hard for any one person to stand out. This is where I come in. Having been in the position of Captain for many years as well as a professional crew recruiter, I know exactly what makes a great CV stand out from the crowd. This includes overall design, specific keywords and every major point that an employer wants to see on a good resume.',
};

export const featuresData = {
  title: 'WHAT WE OFFER',
  subtitle:
    'Here are some of the services that we offer to build and improve your yachting resume',
  list: [
    {
      image: Feature1Img,
      bgImage: Feature1BgImg,
      title: 'Fully Custom Resume',
      description:
        'We will build a unique and custom resume from scratch, making sure to include all the points Captains and Recruiters are looking for. Whether you have zero yachting experience or 20 years, we can help.',
      linkText: 'Learn more',
      delay: '400',
    },
    {
      image: Feature2Img,
      bgImage: Feature2BgImg,
      title: 'Purchase one of our amazing templates',
      description:
        'Download one of our fantastic custom templates that have many of the key points an employer is looking for. Just replace some of the text and photos with your own.',
      linkText: 'Learn more',
      delay: '700',
    },
    {
      image: Feature3Img,
      bgImage: Feature3BgImg,
      title: 'Order custom yachting business cards',
      description:
        'Just met a captain on the dock, but dont have your resume on hand? This is where you need a business card with all of you contact details as well as a QR code that they can use to download your CV in a split second!',
      linkText: 'Learn more',
      delay: '1000',
    },
    {
      image: Feature4Img,
      bgImage: Feature4BgImg,
      title: 'Other services',
      description:
        'Have another request that we havent mentioned yet? Dont worry, just send us a quick message and Im sure we can accomodate you!',
      linkText: 'Learn more',
      delay: '1300',
    },
  ],
};

export const testimonialsData = [
  {
    image: Avatar1Img,
    name: 'John S.',
    web: 'Deckhand',
    message:
      'Alec was such a big help! I thought my resume wasnt bad, but Alec offered to review it for free and came up with some really good points that I should add. I decided to ask him to build a whole new one and Im getting way more calls now.',
    delay: '300',
  },
  {
    image: Avatar2Img,
    name: 'Amanda T. ',
    web: 'Junior Stewardess',
    message:
      'I dont have much yachting experience and my CV looked quite empty to be honest. Alec gave it a whole new remodeling and it looks fantastic! Ive actually had several compliments on it already',
    delay: '600',
  },
  {
    image: Avatar3Img,
    name: 'Jason L.',
    web: 'Yacht Captain',
    message:
      'I realized that I hadnt updated my resume in the past 15 years and it desperately needed a more modern look. It really looks great now! Very happy! ',
    delay: '900',
  },
];

export const ctaData = {
  title: 'Need a resume overhall?',
  subtitle: 'No problem! Let us create a modern looking and effective CV for you!',
  btnText1: 'Learn more',
  btnText2: 'Request a custom resume',
};

export const footerData = {
  logo: LogoV2,
  address: 'Warehouse Society, 234 Bahagia Ave Street PRBW 29281',
  email: 'info@warehouse.project',
  phone: '1-232-3434 (Main)',
  list1: [
    {
      name: 'About',
      href: '#',
    },
    {
      name: 'Tailor-made resumes',
      href: '#',
    },
    {
      name: 'Resume Templates',
      href: '#',
    },
    {
      name: 'Business Cards',
      href: '#',
    },
  ],
  list2: [
    {
      name: 'Support',
      href: '#',
    },
    {
      name: 'Sign Up',
      href: '#',
    },
    {
      name: 'Guide',
      href: '#',
    },
    {
      name: 'Reports',
      href: '#',
    },
    {
      name: 'Q & A',
      href: '#',
    },
  ],
  socialList: [
    {
      icon: <FaYoutube />,
      href: '#',
    },
    {
      icon: <FaInstagram />,
      href: '#',
    },
    {
      icon: <FaGithub />,
      href: '#',
    },
  ],
};

export const copyrightData = {
  text: '© Yacht Resumes™, 2023. All rights reserved.',
  icon: <BsChatDotsFill />,
};

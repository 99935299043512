import React from 'react';

// import about data
import {aboutData} from '../data'

const About = () => {
  // destructure about data
  const {image, title, subtitle} = aboutData;

  return (
    <section className='my-[30px] xl:mt[100px]'
    data-aos="fade-up">
      <div className="container mx-auto">
        <div className='bg-accent-secondary/[15%] items-center rounded-[50px] min-h-[560px] px-12 pb-12 flex flex-col text-center xl:flex-row xl:items-center xl:text-left xl:gap-x-[60px] xl:pb-0'>
          {/* Image */}
          <div className='flex-1 max-w-xs' data-aos="zoom-in-left">
            <img src={image} alt="" />
          </div>
          {/* Text */}
          <div className='flex-1 xl:pr-12'>
            <h2 className='h2 text-2xl md:text-4xl mb-10' data-aos="fade-up">{title}</h2>
            <p className='max-w-[474px] mx-auto xl:mx-0' data-aos="fade-up">{subtitle}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

import React from 'react';

// import hero data
import {heroData} from '../data' 

// import components 
import Header from '../components/Header';

const Hero = () => {

  const { title, subtitle, btnText, image } = heroData;
  return (
    <section className=' py-12'>
      <Header />
      <div className="container mx-auto relative">
        <div className='flex flex-col lg:flex-row pt-5 min-h-screen items-center'>
          {/* Text */}
          <div className='text-center lg:text-left lg:absolute'>
            {/* Title */}
            <h1 className='h1 text-2xl md:text-4xl lg:max-w-[550px] mb-6 lg:mb-12' data-aos='fade-down'>
              {title}</h1>
            {/* Subtitle */}
            <p className='lead text-base lg:max-w-[380px] mb-6 lg:mb-12' 
            data-aos='fade-down'>
              {subtitle}</p>
            <button className='btn btn-primary mb-8 lg:mb-0'
            data-aos='fade-down'>
              {btnText}</button>
          </div>
          {/* Image */}
          <div className='lg:absolute max-w-md lg:right-3'>
            <img className='' data-aos='fade-up' src={image} alt="Resume Building" />
          </div>

        </div>
      </div>
    </section>
  );
};

export default Hero;
